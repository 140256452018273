<div class="row row-cols-md-4 row-cols-sm-1 mx-0 my-3 px-2 section-1">
    <div class="col-md-3 col-6 p-2">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body atm-card-top">
                <!-- <h1>371</h1> -->
                <app-countTotalRegistrationByPump [pumpID]="pumpID"></app-countTotalRegistrationByPump>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>Today's Registrations</h5>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6 p-2">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body customer-card-top">
                <!-- <h1>114,608</h1> -->
                <app-countTotalCustomerByPump [pumpID]="pumpID"></app-countTotalCustomerByPump>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>Today's Customers</h5>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6 p-2">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body dispense-card-top">
                <!-- <h1>17,925</h1> -->
                <app-sumTodayDispenseByPump [pumpID]="pumpID"></app-sumTodayDispenseByPump>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>Today's Dispense (Ltr)</h5>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6 p-2">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body recharge-card-top">
                <!-- <h1>19,437</h1> -->
                <app-sumTodayRechargeByPump [pumpID]="pumpID"></app-sumTodayRechargeByPump>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>Today's Recharge (BDT)</h5>
            </div>
        </div>
    </div>
</div>
<!-- overview cards end-->

<!--charts start-->

<div class="row row-cols-md-3 row-cols-sm-1 mx-0 my-2 px-2 section-2">
    <div class="col-md-4 col-12 p-2">
        <app-monthlyRegisteredCustomerGraphByPump [pumpID]="pumpID"></app-monthlyRegisteredCustomerGraphByPump>
    </div>
    <div class="col-md-4 col-12 p-2">
        <app-monthlyWaterDispenseGraphByPump [pumpID]="pumpID"></app-monthlyWaterDispenseGraphByPump>
    </div>
    <div class="col-md-4 col-12 p-2">
        <app-monthlyRechargeGraphByPump [pumpID]="pumpID"></app-monthlyRechargeGraphByPump>
    </div>
</div>

<!--charts end-->

<!--map and pump section start-->
<div class="row px-2 row-3 section-3 mx-0">
    <div class="col-lg-2 col-md-3 col-12 pt-2 px-2  pump-col">
        <div class="map-topbar d-flex justify-content-between px-3 py-2 header">
            <div>
                <span class="text-white">Pump Details</span>
            </div>
        </div>
        <div class="bg-white map-shadow details">
            
            <div class="px-3 pt-1">
                <label class="form-label mb-0">Pump Name</label>
                <label style="color: rgb(0, 0, 0); display: block; font-size: 10px;">{{pumpDetail.PumpName}}</label>
            </div>
            <hr class="hr" />
            <div class="px-3 pt-1">
                <label class="form-label mb-0">Zone</label>
                <label style="color: rgb(0, 0, 0); display: block; font-size: 10px;">{{pumpDetail.ZoneName}}</label>
            </div>
            <hr class="hr" />
            <div class="px-3 pt-1">
                <label class="form-label mb-0">Address</label>
                <label style="color: rgb(0, 0, 0); display: block; font-size: 10px;">{{pumpDetail.Location}}</label>
            </div>
            <hr class="hr" />
            <div class="px-3 pt-1">
                <label class="form-label mb-0">Operating Hours</label>
                <label style="color: rgb(0, 0, 0); display: block; font-size: 10px;">{{pumpDetail.OperatingHourStart+' to '+pumpDetail.OperatingHourEnd}}</label>
            </div>
            <div class="px-3 pt-1 pump-photo">
                <img [src]="pumpDetail.Photo" onerror="this.src='../../../assets/images/Moghbazar Wireless Pump.png'">
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-5 col-12 pt-2 px-2 pump-col">
        <div class="map-topbar d-flex justify-content-between px-3 py-2 header map-shadow">
            <div>
                <span><i class="fas fa-map text-white"></i></span>
                <span class="ps-3 text-white">Pump Locations</span>
            </div>
            <div>
                <span><i class="fas fa-share-alt text-white pe-3"></i></span>
                <span><i class="fas fa-expand text-white"></i></span>
            </div>
        </div>
        <div class="details map-shadow">
            <app-pump-map [listPump]="[pumMap]" [centerLoc]="pumMap" ></app-pump-map>
        </div>
    </div>
    <div class="col-md-4 col-12 pt-2 px-1 pump-col">
        <app-transaction-list [headerTitle]="'Latest Transactions'" [pumpID]="pumpID"></app-transaction-list>
    </div>
</div>
<!--map and pump section end-->