import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '../common/constant/api-constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DashboardModernizationService = /** @class */ (function () {
    function DashboardModernizationService(http) {
        this.http = http;
    }
    DashboardModernizationService.prototype.GetTotalATM = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetTotalATM);
    };
    DashboardModernizationService.prototype.GetTotalCustomer = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetTotalCustomer);
    };
    DashboardModernizationService.prototype.GetTodayDispense = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetTodayDispense);
    };
    DashboardModernizationService.prototype.GetTodayRecharge = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetTodayRecharge);
    };
    DashboardModernizationService.prototype.getAllMonthlyRegisteredCustomer = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetAllMonthlyRegisteredCustomer);
    };
    DashboardModernizationService.prototype.getAllMonthlyWaterDispense = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetAllMonthlyWaterDispense);
    };
    DashboardModernizationService.prototype.getAllMonthlyRecharge = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetAllMonthlyRecharge);
    };
    DashboardModernizationService.prototype.getLastWaterWithdrawal = function () {
        return this.http.get(ApiConstant.DashboardModernizationApi.GetLastWaterWithdrawal);
    };
    DashboardModernizationService.prototype.GetPumpList = function () {
        return this.http.post(ApiConstant.DashboardModernizationApi.GetAllPump, {});
    };
    // ......///
    DashboardModernizationService.prototype.GetWeeklyAnalysReport = function (recarchcategory) {
        return this.http.post(ApiConstant.DashboardModernizationApi.GetDashBoardWeeklyAnalysDate, recarchcategory);
    };
    DashboardModernizationService.prototype.GetByPumName = function (recarchcategory) {
        return this.http.post(ApiConstant.DashboardModernizationApi.GetReportByPumName, recarchcategory);
    };
    DashboardModernizationService.prototype.GetAllZone = function () {
        return this.http.post(ApiConstant.ZoneApi.GetAllZone, {});
    };
    DashboardModernizationService.prototype.GetByZone = function (recarchcategory) {
        return this.http.post(ApiConstant.DashboardModernizationApi.GetByZone, recarchcategory);
    };
    DashboardModernizationService.prototype.GetByLocationName = function (recarchcategory) {
        return this.http.post(ApiConstant.DashboardModernizationApi.GetByLocationName, recarchcategory);
    };
    DashboardModernizationService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardModernizationService_Factory() { return new DashboardModernizationService(i0.inject(i1.HttpClient)); }, token: DashboardModernizationService, providedIn: "root" });
    return DashboardModernizationService;
}());
export { DashboardModernizationService };
