import { DashboardModernizationService } from './../../../apiServices/dashboardModernization.service';
import { Component, OnDestroy } from '@angular/core';
import { RELOAD_INTERVAL_TIME } from 'src/app/common/constant/app-constants';

@Component({
  selector: 'app-sumTodayDispense',
  templateUrl: './sumTodayDispense.component.html',
  styleUrls: ['./sumTodayDispense.component.scss']
})
export class SumTodayDispenseComponent implements OnDestroy {

  sumTodayDispense: number;
  isLoading:boolean = false;

  private reloadInterval;

  constructor(private dashboardService: DashboardModernizationService) {
  }

  ngOnInit() {
    this.getTodayDispense();
    this.reloadInterval = setInterval(()=>{
      this.getTodayDispense();
    },RELOAD_INTERVAL_TIME)
  }

  ngOnDestroy(): void {
      if(this.reloadInterval){
        clearInterval(this.reloadInterval);
      }
  }

  getTodayDispense() {
    this.isLoading = true;
    this.dashboardService.GetTodayDispense()
      .subscribe(
        (res: any) => {
          this.sumTodayDispense = res;
          //hide loader
          this.isLoading = false;
        },
        err => this.isLoading = false);
  }
}










