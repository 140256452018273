import { DashboardModernizationService } from './../../../apiServices/dashboardModernization.service';
import { Component, OnDestroy } from '@angular/core';
import { vmLastWaterWithdrawal } from 'src/app/models/ViewModels/vmLastWaterWithdrawal';
import { RELOAD_INTERVAL_TIME, RELOAD_INTERVAL_TIME_LATEST_WINTHDRAW } from 'src/app/common/constant/app-constants';

@Component({
  selector: 'app-lastWaterWithdrawal',
  templateUrl: './lastWaterWithdrawal.component.html',
})
export class LastWaterWithdrawalComponent implements OnDestroy{

  strLastWaterWithdrawal: string;
  lastWaterWithdrawal: vmLastWaterWithdrawal;

  private reloadInterval;

  constructor(private dashboardService: DashboardModernizationService) {
  }

  ngOnInit() {
    this.getLastWaterWithdrawal();
    this.reloadInterval = setInterval(()=>{
      this.getLastWaterWithdrawal();
    },RELOAD_INTERVAL_TIME_LATEST_WINTHDRAW)
  }

  ngOnDestroy(): void {
      if(this.reloadInterval){
        clearInterval(this.reloadInterval);
      }
  }

  getLastWaterWithdrawal() {
    this.dashboardService.getLastWaterWithdrawal()
      .subscribe(
        (res: any) => {
          this.lastWaterWithdrawal = res;
          const d = new Date(this.lastWaterWithdrawal.CreatedDate + 'Z');
          this.strLastWaterWithdrawal = 'Pump: ' + this.lastWaterWithdrawal.PumpName
            + ', Customer: ' + this.lastWaterWithdrawal.CustomerName + ', Time: ' +
            d.toLocaleTimeString() + ', Water Dispensed: ' +
            this.lastWaterWithdrawal.WaterAmount + ' Ltr, Spending Tk. ' +
            this.lastWaterWithdrawal.WaterPrice;
        },
        err => null);
  }


  // (d.getHours() + ':' + d.getMinutes())

  public convertUTCDateToLocalDate(date): Date {
    let newdate = new Date(date);
    var newDate = new Date(newdate.getTime() - newdate.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

}










