import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../apiServices/auth/auth.service';
import { AuthorizationService } from 'src/app/apiServices/auth/authorization.service';
declare let $: any;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    // isSettingOpen = false;
    // isReportOpen = false;
    constructor(public authService: AuthService) {}

    ngOnInit() {

    }

    // ngOnInit() {
    // //   $('.main-sidebar').pushMenu('collapseScreenSize');
    // $(document).ready( function() {
    //         if ($(window).width() < 514) {
    //             $('body').addClass('mobile');
    //         } else {
    //             $('body').removeClass('mobile');
    //             }
    //         });

    //         $(document).on('click' , '.mobile  .mobile_menu' , function(e) {
    //              //  $('body').addClass('sidebar-mini sidebar-collapse');
    //             $('body').removeClass('sidebar-open');
    //             // e.stopImmediatePropagation();
    //         e.preventDefault();
    //     });
    // }

    // openSettingMenu() {
    //     this.isSettingOpen = !this.isSettingOpen;
    // }

    // openReportMenu() {
    //     this.isReportOpen = !this.isReportOpen;
    // }
}
