import { DashboardModernizationService } from './../../../apiServices/dashboardModernization.service';
import { Component, OnDestroy } from '@angular/core';
import { RELOAD_INTERVAL_TIME } from 'src/app/common/constant/app-constants';

@Component({
  selector: 'app-countTotalATM',
  templateUrl: './countTotalATM.component.html',
  styleUrls: ['./countTotalATM.component.scss']
})
export class CountTotalATMComponent implements OnDestroy {

  totalATM: number;
  isLoading:boolean = false;

  private realoadInterval;

  constructor(private dashboardService: DashboardModernizationService) {
  }

  ngOnInit() {
    this.getTotalATM();
    this.realoadInterval = setInterval(()=>{
      this.getTotalATM();
    },RELOAD_INTERVAL_TIME)
  }

  ngOnDestroy(): void {
    if(this.realoadInterval){
      clearInterval(this.realoadInterval);
    }
  }

  getTotalATM() {
    this.isLoading = true;
    this.dashboardService.GetTotalATM()
      .subscribe(
        (res: any) => {
          this.totalATM = res;
          this.isLoading = false;
          //hide loader
          //document.getElementById('loaderATM').style.display = 'none';
        },
        err => {
          this.isLoading = false;
        });
  }
}

