import { Component, Input, OnInit } from '@angular/core';
import { PumpView } from 'src/app/models/ViewModels/dashboard-model';

@Component({
  selector: 'app-pump-map',
  templateUrl: './pump-map.component.html',
  styleUrls: ['./pump-map.component.scss']
})
export class PumpMapComponent implements OnInit {

  @Input() listPump:PumpView[]=[];
  @Input() centerLoc:PumpView = new PumpView();

  constructor() { }

  ngOnInit() {
    if(!this.centerLoc.Lat && !this.centerLoc.Lng){
      this.setCurrentLoc();
    }
  }

  setCurrentLoc(){
    navigator.geolocation.getCurrentPosition(loc=>{
      this.centerLoc.Lat= loc.coords.latitude;
      this.centerLoc.Lng = loc.coords.longitude;
    })
  }

}
