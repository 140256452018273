import { Component, Input } from '@angular/core';
import { DashboardModernizationByPumpService } from 'src/app/apiServices/dashboardModernizationByPump.service';

@Component({
  selector: 'app-monthlyRechargeGraphByPump',
  templateUrl: './monthlyRechargeGraphByPump.component.html',
})
export class MonthlyRechargeGraphByPumpComponent {
  monthlyRecharge = [];
  data = []
  monthNames = [];
  isDataUpdate: boolean = false;
  @Input() pumpID: number;

  constructor(private dashboardService: DashboardModernizationByPumpService) {
  }

  ngOnInit() {
    this.getAllMonthlyRechargeByPump();
  }

  getAllMonthlyRechargeByPump() {
    this.dashboardService.GetAllMonthlyRechargeByPump(this.pumpID)
      .subscribe(
        (res: any) => {
          //push data in graph
          res.forEach(x => {
            this.data.push(x.Total);
            this.monthNames.push(x.MonthName);
          });
          //to update graph
          this.isDataUpdate = true;
        });
  }

  chartOptions = {
    responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
  }

  //labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  labels = this.monthNames;
  datasets = [
    {
      label: 'Montly Recharge Amount (BDT)',
      data: this.data
    }
  ];

  // CHART COLOR.
  colors = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)'
      ],
      borderColor: [
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)'
      ],
      borderWidth: 1
    }
  ]

  // CHART CLICK EVENT.
  onChartClick(event) {
   // console.log(event);
  }

}










