import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { MenuCardComponent } from './menu-card/menu-card.component';
import { MenuListComponent } from './menu-list/menu-list.component';

@NgModule({
  declarations: [
    HomeComponent,
    MenuCardComponent,
    MenuListComponent
  ],
  imports: [
    CommonModule
  ]
})
export class HomeModule { }
