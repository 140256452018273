import { Component, Input } from '@angular/core';
import { DashboardModernizationByPumpService } from 'src/app/apiServices/dashboardModernizationByPump.service';

@Component({
  selector: 'app-monthlyRegisteredCustomerGraphByPump',
  templateUrl: './monthlyRegisteredCustomerGraphByPump.component.html',
})
export class MonthlyRegisteredCustomerGraphByPumpComponent {

  monthlyRegisteredCustomers = [];
  data = [];
  monthNames = [];
  isDataUpdate: boolean = false;
  @Input() pumpID: number;

  constructor(private dashboardService: DashboardModernizationByPumpService) {
  }

  ngOnInit() {
    this.getAllMonthlyRegisteredCustomerByPump();
  }

  chartOptions = {
    responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
  }

  //labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  labels = this.monthNames;
  datasets = [
    {
      label: 'Monthly New Cards Sold',
      data: this.data
    }
  ];

  // CHART COLOR.
  colorsline = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(252, 188, 60,.2)'
      ],
      borderColor: [
        'rgba(58, 221, 203,.8)'
      ],
      borderWidth: 1
    }
  ]

  // CHART CLICK EVENT.
  onChartClick(event) {
   // console.log(event);
  }

  getAllMonthlyRegisteredCustomerByPump() {
    this.dashboardService.GetAllMonthlyRegisteredCustomerByPump(this.pumpID)
      .subscribe(
        (res: any) => {
          //push data in graph
          res.forEach(x => {
            this.data.push(x.Total);
            this.monthNames.push(x.MonthName);
          });
          //to update graph
          this.isDataUpdate = true;
        });
  }
}










