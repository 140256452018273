import { DashboardModernizationService } from './../../../apiServices/dashboardModernization.service';
import { Component } from '@angular/core';
import { vmSumMonthwiseTotal } from 'src/app/models/ViewModels/vmSumMonthwiseTotal';
import 'chartjs-plugin-zoom';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-monthlyRegisteredCustomerGraph',
  templateUrl: './monthlyRegisteredCustomerGraph.component.html',
  styles: []
})
export class MonthlyRegisteredCustomerGraphComponent {

  monthlyRegisteredCustomers = [];
  data = [];
  monthNames = []
  isDataUpdate: boolean = false;

  constructor(private dashboardService: DashboardModernizationService) {
  }

  ngOnInit() {
    this.getAllMonthlyRegisteredCustomer();
  }

  chartOptions = {
    responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
          threshold: 5,
        },
        zoom: {
          enabled: true,
          mode: 'xy',
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
        }
      }
    }
  }

  ///labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  labels = this.monthNames;
  datasets = [
    {
      label: 'Monthly New Cards Sold',
      data: this.data
    }
  ];

  // CHART COLOR.
  colorsline = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(252, 188, 60,.2)'
      ],
      borderColor: [
        'rgba(58, 221, 203,.8)'
      ],
      borderWidth: 1
    }
  ]

  // CHART CLICK EVENT.
  onChartClick(e) {
    //console.log(e);
  }

  getAllMonthlyRegisteredCustomer() {
    this.dashboardService.getAllMonthlyRegisteredCustomer()
      .subscribe(
        (res: any) => {
          //push data in graph
          res.forEach(x => {
            this.data.push(x.Total);
            this.monthNames.push(x.MonthName);
          });

          //to update graph
          this.isDataUpdate = true;
        });
  }

}










