import { JwtHelperService } from '@auth0/angular-jwt';
import * as i0 from "@angular/core";
var LOCAL_STORAGE_KEY = 'token';
var TokenService = /** @class */ (function () {
    function TokenService() {
        this.jwtHelper = new JwtHelperService();
    }
    TokenService.prototype.GetToken = function () {
        return localStorage.getItem(LOCAL_STORAGE_KEY);
    };
    TokenService.prototype.SaveToken = function (token) {
        localStorage.setItem(LOCAL_STORAGE_KEY, "Bearer " + token);
    };
    TokenService.prototype.RemoveToken = function () {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };
    TokenService.prototype.GetTokenExpiration = function () {
        return this.jwtHelper.getTokenExpirationDate(this.GetToken());
    };
    TokenService.prototype.isTokenExpired = function () {
        return this.jwtHelper.isTokenExpired(this.GetToken());
    };
    TokenService.prototype.DecodeToken = function () {
        return this.jwtHelper.decodeToken(this.GetToken());
    };
    TokenService.prototype.GetTokenValue = function (key) {
        var decodeObj = this.DecodeToken();
        return decodeObj[key];
    };
    TokenService.ngInjectableDef = i0.defineInjectable({ factory: function TokenService_Factory() { return new TokenService(); }, token: TokenService, providedIn: "root" });
    return TokenService;
}());
export { TokenService };
