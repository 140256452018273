<app-header></app-header>

<div class="content-wrapper">
    <section class="content" style="position: relative;">
        <div [hidden]="!loader.isLoader" class="load-bar">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>

        <router-outlet></router-outlet>

    </section>
</div>

<app-footer></app-footer>


