/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pump-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@agm/core/directives/marker";
import * as i3 from "@agm/core/services/managers/marker-manager";
import * as i4 from "../../../../node_modules/@agm/core/directives/info-window.ngfactory";
import * as i5 from "@agm/core/directives/info-window";
import * as i6 from "@agm/core/services/managers/info-window-manager";
import * as i7 from "../../../../node_modules/@agm/core/directives/map.ngfactory";
import * as i8 from "@agm/core/services/google-maps-api-wrapper";
import * as i9 from "@agm/core/services/managers/circle-manager";
import * as i10 from "@agm/core/services/managers/polyline-manager";
import * as i11 from "@agm/core/services/managers/polygon-manager";
import * as i12 from "@agm/core/services/managers/kml-layer-manager";
import * as i13 from "@agm/core/services/managers/data-layer-manager";
import * as i14 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i15 from "@agm/core/directives/map";
import * as i16 from "@angular/common";
import * as i17 from "./pump-map.component";
var styles_PumpMapComponent = [i0.styles];
var RenderType_PumpMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PumpMapComponent, data: {} });
export { RenderType_PumpMapComponent as RenderType_PumpMapComponent };
function View_PumpMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "agm-marker", [], null, null, null, null, null)), i1.ɵdid(1, 1720320, null, 1, i2.AgmMarker, [i3.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"] }, null), i1.ɵqud(603979776, 1, { infoWindow: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "agm-info-window", [], null, null, null, i4.View_AgmInfoWindow_0, i4.RenderType_AgmInfoWindow)), i1.ɵdid(4, 770048, [[1, 4]], 0, i5.AgmInfoWindow, [i6.InfoWindowManager, i1.ElementRef], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, 0, ["", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.Lat; var currVal_1 = _v.context.$implicit.Lng; _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.PumpName; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.Location; _ck(_v, 8, 0, currVal_3); }); }
export function View_PumpMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "agm-map", [], [[2, "sebm-google-map-container", null]], null, null, i7.View_AgmMap_0, i7.RenderType_AgmMap)), i1.ɵprd(4608, null, i3.MarkerManager, i3.MarkerManager, [i8.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i6.InfoWindowManager, i6.InfoWindowManager, [i8.GoogleMapsAPIWrapper, i1.NgZone, i3.MarkerManager]), i1.ɵprd(4608, null, i9.CircleManager, i9.CircleManager, [i8.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i10.PolylineManager, i10.PolylineManager, [i8.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i11.PolygonManager, i11.PolygonManager, [i8.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i12.KmlLayerManager, i12.KmlLayerManager, [i8.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i13.DataLayerManager, i13.DataLayerManager, [i8.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i8.GoogleMapsAPIWrapper, i8.GoogleMapsAPIWrapper, [i14.MapsAPILoader, i1.NgZone]), i1.ɵdid(9, 770048, null, 0, i15.AgmMap, [i1.ElementRef, i8.GoogleMapsAPIWrapper], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PumpMapComponent_1)), i1.ɵdid(11, 278528, null, 0, i16.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.centerLoc.Lng; var currVal_2 = _co.centerLoc.Lat; var currVal_3 = 11; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.listPump; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_PumpMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pump-map", [], null, null, null, View_PumpMapComponent_0, RenderType_PumpMapComponent)), i1.ɵdid(1, 114688, null, 0, i17.PumpMapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PumpMapComponentNgFactory = i1.ɵccf("app-pump-map", i17.PumpMapComponent, View_PumpMapComponent_Host_0, { listPump: "listPump", centerLoc: "centerLoc" }, {}, []);
export { PumpMapComponentNgFactory as PumpMapComponentNgFactory };
