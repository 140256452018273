import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../common/utility/loader.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(public loader: LoaderService) { }

  ngOnInit() {
  }

}
