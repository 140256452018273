/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "footer", [["class", "main-footer text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Copyright \u00A9 2022 "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "http://nybsys.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nybsys"])), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵted(-1, null, [" All rights reserved.\n"]))], null, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
