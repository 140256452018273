<header class="main-header">

    <!-- Logo -->
    <a routerLink="/home" class="logo desktopVisibility ">
        <!-- mini logo for sidebar mini 50x50 pixels -->
        <span class="logo-mini">
            <img src="assets/img/drinkwell-logo-mini.png" width="35px" width="35px" alt="DrinkWell">
        </span>
        <!-- logo for regular state and mobile devices -->
        <span class="logo-lg">
            <img src="assets/img/logo.png" width="120" alt="nybsys">
        </span>
    </a>

    <!-- Header Navbar: style can be found in header.less -->
    <div class="d-flex flex-row justify-content-between align-items-center pe-5 pb-2" style="background-color: #fff;">
        <div>
            <!-- Sidebar toggle button-->
            <a class="sidebar-toggle" data-toggle="push-menu" role="button">
                <span class="sr-only">Toggle navigation</span>
            </a>
        </div>
        <div class="">
            <!-- <a [routerLink]="['/setting/user/change-password']" class="me-3">
                <strong>Change Password</strong>
            </a>

            <a (click)="authService.removeToken()" style="cursor: pointer;" class="fa fa-sign-out">
                <strong>Log Out</strong>
            </a> -->
            
            <!-- <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                </button>
                <ul class="dropdown-menu dropleft">
                    <li><a href="#">Change Password</a></li>
                    <li><a href="#">Log Out</a></li>
                  </ul>
              </div> -->
              <div class="btn-group dropleft">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-user fa-lg p-2"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" style="width: 6vw;">
                  <ul style="list-style-position: inside; list-style-type: none;">
                    <li class="mt-1"><a [routerLink]="['/setting/user/change-password']">Change Password</a></li>
                    <li class="mt-2"><a (click)="authService.removeToken()" [routerLink]="['']">Log Out</a></li>
                  </ul>
                </div>
              </div>
              
            <!-- <button type="button" class="btn">
                <span class="glyphicon glyphicon-cog"></span>
            </button> -->
        </div>
    </div>
</header>

<app-sidebar></app-sidebar>