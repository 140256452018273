import { DashboardModernizationService } from './../../apiServices/dashboardModernization.service';
import { Component, OnInit } from '@angular/core';
import { DashboardVm, PumpView } from 'src/app/models/ViewModels/dashboard-model';

@Component({
  selector: 'app-dashboard-main-view-new',
  templateUrl: './dashboard-main-view-new.component.html',
  styleUrls: [
    './dashboard-main-view-new.component.scss',
    '../../../assets/css/dashboard.css'
  ]
})
export class DashboardMainViewNewComponent implements OnInit {
  public objDashboard: DashboardVm = new DashboardVm();
  listPump:PumpView[]=[];

  chartOptions = {
    responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
  }

  labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  chartData = [
    {
      label: 'Montly Water Dispense (Ltr)',
      data: [40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 100]
    }
  ];

  // CHART COLOR.
  colors = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgba(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)'
      ],
      borderColor: [
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)'
      ],
      borderWidth: 1
    }
  ]


  chartDataRecharge = [
    {
      label: 'Montly Recharge Amount (BDT)',
      data: [32, 15, 27, 25, 8, 23, 24, 32, 39.10, 7, 27, 23]
    }
  ];

  // CHART COLOR.
  colorsRecharge = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)'
      ],
      borderColor: [
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)'
      ],
      borderWidth: 1
    }
  ]
  //..........
  chartDataline = [
    {
      label: 'Monthly New Cards Sold',
      data: [3, 15, 27, 25, 8, 23, 24, 32, 36.10, 7]
    }
  ];

  // CHART COLOR.
  colorsline = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(252, 188, 60,.2)'
      ],
      borderColor: [
        'rgba(58, 221, 203,.8)'
      ],
      borderWidth: 1
    }
  ]


  // CHART CLICK EVENT.
  onChartClick(event) {
   // console.log(event);
  }

  constructor(private dashboardService: DashboardModernizationService) {

  }

  ngOnInit() {
    // this.dashboardService.GetStatus()
    // .subscribe(
    //     (res) => {
    //         this.objDashboard = <DashboardVm>res;
    //     }
    // );

    // debugger;
    // this.dashboardService.GetTotalATM()
    //   .subscribe(
    //     (res: any) => {
    //       console.log("Active ATM", res)
    //     }
    //   );

      this.getPumpList();


    //     this.dashboardService.GetActiveATM()
    //     .subscribe(
    //         (res:any) => {
    //             console.log("Active ATM",res)
    //             this.objDashboard.ActiveBooth = res;
    //         }
    //     );
    //     this.dashboardService.GetActiveCustomer()
    //     .subscribe(
    //         (res:any) => {
    //             console.log("Active Customer",res)

    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.ActiveCustomer = res;

    //         }
    //     );
    //     this.dashboardService.GetLatestWaterDispenses()
    //     .subscribe(
    //         (res:any) => {
    //             console.log("LatestWaterDispense",res)
    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.LstWaterTransaction = res;

    //         }
    //     );
    //     // this.dashboardService.GetMonthlyWaterDispense()
    //     // .subscribe(
    //     //     (res:any) => {
    //     //         console.log("Monthy Water Dispense",res)

    //     //         // this.objDashboard = <DashboardVm>res;
    //     //         // this.objDashboard.MonthlyWaterDispense = res;

    //     //     }
    //     // );
    //     // this.dashboardService.GetNewAccountCountByMonth()
    //     // .subscribe(
    //     //     (res:any) => {
    //     //         console.log("GetNewAccountCountByMonth",res)

    //     //         // this.objDashboard = <DashboardVm>res;
    //     //         // this.objDashboard.NewAccountCountByMonth = res;

    //     //     }
    //     // );
    //     // this.dashboardService.GetPumpList()
    //     // .subscribe(
    //     //     (res:any) => {
    //     //         console.log("GetPumpList",res)

    //     //         // this.objDashboard = <DashboardVm>res;
    //     //         // this.objDashboard.LstPump = res;

    //     //     }
    //     // );
    //     // this.dashboardService.GetRechargeSumByMonth()
    //     // .subscribe(
    //     //     (res:any) => {
    //     //         console.log("GetRechargeSumByMonth",res)

    //     //         // this.objDashboard = <DashboardVm>res;
    //     //         // this.objDashboard.RechargeSumByMonth = res;

    //     //     }
    //     // );
    //     // this.dashboardService.GetTodayWaterDispense()
    //     // .subscribe(
    //     //     (res:any) => {
    //     //         console.log("GetTodayWaterDispense",res)

    //     //         // this.objDashboard = <DashboardVm>res;
    //     //         // this.objDashboard.TodayWaterDispense = res;

    //     //     }
    //     // );

    //     // this.dashboardService.GetWaterDispenseSumByMonth()
    //     // .subscribe(
    //     //     (res:any) => {
    //     //         console.log("GetWaterDispenseSumByMonth",res)

    //     //         // this.objDashboard = <DashboardVm>res;
    //     //         // this.objDashboard.WaterDispenseSumByMonth = res;

    //     //     }
    //     // );

    // }

    // GetActiveATM(){
    //     this.dashboardService.GetActiveATM()
    //     .subscribe(
    //         (res:any) => {
    //             console.log("Active ATM",res)
    //             this.objDashboard.ActiveBooth = res;
    //         }
    //     );

    // }

    // GetActiveCustomer(){
    //     this.dashboardService.GetActiveCustomer()
    //     .subscribe(
    //         (res:any) => {
    //             console.log("Active Customer",res)

    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.ActiveCustomer = res;

    //         }
    //     );

    // }
    // GetLatestWaterDispenses(){
    //     this.dashboardService.GetLatestWaterDispenses()
    //     .subscribe(
    //         (res:any) => {
    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.LstWaterTransaction = res;

    //         }
    //     );

    // }
    // GetMonthlyWaterDispense(){
    //     this.dashboardService.GetMonthlyWaterDispense()
    //     .subscribe(
    //         (res:any) => {
    //             console.log("Monthy Water Dispense",res)

    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.MonthlyWaterDispense = res;

    //         }
    //     );

    // }
    // GetNewAccountCountByMonth(){
    //     this.dashboardService.GetNewAccountCountByMonth()
    //     .subscribe(
    //         (res:any) => {
    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.NewAccountCountByMonth = res;

    //         }
    //     );

    // }
    // GetPumpList(){
    //     this.dashboardService.GetPumpList()
    //     .subscribe(
    //         (res:any) => {
    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.LstPump = res;

    //         }
    //     );

    // }
    // GetRechargeSumByMonth(){
    //     this.dashboardService.GetRechargeSumByMonth()
    //     .subscribe(
    //         (res:any) => {
    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.RechargeSumByMonth = res;

    //         }
    //     );

    // }
    // GetTodayWaterDispense(){
    //     this.dashboardService.GetTodayWaterDispense()
    //     .subscribe(
    //         (res:any) => {
    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.TodayWaterDispense = res;

    //         }
    //     );


    // }
    // GetWaterDispenseSumByMonth(){
    //     this.dashboardService.GetWaterDispenseSumByMonth()
    //     .subscribe(
    //         (res:any) => {
    //             // this.objDashboard = <DashboardVm>res;
    //             this.objDashboard.WaterDispenseSumByMonth = res;

    //         }
    //     );
    // }
  }
  getPumpList(){
    
    this.dashboardService.GetPumpList()
    .subscribe(
        (res:any) => {
        if(res.StatusCode == 200){
            this.listPump = (res.ResponseObject as PumpView[]).map(item=>{
              item.Lat = Number(item.Lat);
              item.Lng = Number(item.Lng);
              return item;
            });
        }
        }, err=>{
        //  console.log(err);
        });
  }
}
