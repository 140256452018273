<agm-map [latitude]="centerLoc.Lat" [longitude]="centerLoc.Lng" [zoom]="11">

  <agm-marker *ngFor="let marker of listPump" [latitude]="marker.Lat"
      [longitude]="marker.Lng">
      <agm-info-window>
          <p> {{marker.PumpName}}</p>
          <p></p>{{ marker.Location }}
      </agm-info-window>
  </agm-marker>

</agm-map>