import { ToastrModule } from 'ngx-toastr';
// imports libary
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

//import component as pages
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { DashboardMainViewNewComponent } from './pages/dashboard-main-view-new/dashboard-main-view-new.component'; 
import { WithdrawReportNormalComponent } from './components/dashboard/withdraw-report-normal/withdraw-report-normal.component';
import { BoothTableComponent } from './components/dashboard/booth-table/booth-table.component';
import { httpInterceptorProviders } from './common/utility/interceptor';
import { CustomerCountViewComponent } from './components/dashboard/customer-count-view/customer-count-view.component';
import { MonthlyWaterDispenseComponent } from './components/dashboard/bar-chart/monthly-water-dispense/monthly-water-dispense.component';
import { MonthlyRechargeComponent } from './components/dashboard/bar-chart/monthly-recharge/monthly-recharge.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MapPumpMarkerComponent } from './components/dashboard/map-pump-marker/map-pump-marker.component';
import { ChartsModule } from 'ng2-charts'
import { DashboardPumpDetailComponent } from './pages/dashboard-pump-detail/dashboard-pump-detail.component';
import { CountTotalATMComponent } from './components/deshboardModernization/countTotalATM/countTotalATM.component';
import { CountTotalCustomerComponent } from './components/deshboardModernization/countTotalCustomer/countTotalCustomer.component';
import { SumTodayRechargeComponent } from './components/deshboardModernization/sumTodayRecharge/sumTodayRecharge.component';
import { SumTodayDispenseComponent } from './components/deshboardModernization/sumTodayDispense/sumTodayDispense.component';
import { MonthlyRegisteredCustomerGraphComponent} from './components/deshboardModernization/monthlyRegisteredCustomerGraph/monthlyRegisteredCustomerGraph.component';
import { MonthlyRechargeGraphComponent } from './components/deshboardModernization/monthlyRechargeGraph/monthlyRechargeGraph.component';
import { MonthlyWaterDispenseGraphComponent } from './components/deshboardModernization/monthlyWaterDispenseGraph/monthlyWaterDispenseGraph.component';
import { CountTotalCustomerByPumpComponent } from './components/deshboardModernizationByPump/countTotalCustomerByPump/countTotalCustomerByPump.component';
import { CountTotalRegistrationByPumpComponent } from './components/deshboardModernizationByPump/countTotalRegistrationByPump/countTotalRegistrationByPump.component';
import { SumTodayDispenseByPumpComponent } from './components/deshboardModernizationByPump/sumTodayDispenseByPump/sumTodayDispenseByPump.component';
import { SumTodayRechargeByPumpComponent } from './components/deshboardModernizationByPump/sumTodayRechargeByPump/sumTodayRechargeByPump.component';
import { MonthlyWaterDispenseGraphByPumpComponent } from './components/deshboardModernizationByPump/monthlyWaterDispenseGraphByPump/monthlyWaterDispenseGraphByPump.component';
import { MonthlyRegisteredCustomerGraphByPumpComponent } from './components/deshboardModernizationByPump/monthlyRegisteredCustomerGraphByPump/monthlyRegisteredCustomerGraphByPump.component';
import { MonthlyRechargeGraphByPumpComponent } from './components/deshboardModernizationByPump/monthlyRechargeGraphByPump/monthlyRechargeGraphByPump.component';
import { TransactionListComponent } from './components/deshboardModernizationByPump/transaction-list/transaction-list.component';
import { PumpMapComponent } from './components/pump-map/pump-map.component';
import { HomeComponent } from './pages/home/home.component';
import { HomeModule } from './pages/home/home.module';
import { WeeklyDashboardReportComponent } from './components/deshboardModernization/weekly-dashboard-report/weekly-dashboard-report.component';
import { LatestWaterWithdrawalByPumpComponent } from './components/deshboardModernizationByPump/latestWaterWithdrawalByPump/latestWaterWithdrawalByPump.component';
import { LastWaterWithdrawalComponent } from './components/deshboardModernization/lastWaterWithdrawal/lastWaterWithdrawal.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        LayoutComponent,
        // DashboardMainViewComponent,
        DashboardMainViewNewComponent,
        DashboardPumpDetailComponent,
        WithdrawReportNormalComponent,
        BoothTableComponent,
        CustomerCountViewComponent,
        MonthlyWaterDispenseComponent,
        MonthlyRechargeComponent,
        SidebarComponent,
        MapPumpMarkerComponent,
        WeeklyDashboardReportComponent,
        CountTotalATMComponent,
        CountTotalCustomerComponent,
        SumTodayRechargeComponent,
        SumTodayDispenseComponent,
        MonthlyRegisteredCustomerGraphComponent,        
        MonthlyWaterDispenseGraphComponent,
        MonthlyRechargeGraphComponent,
        CountTotalCustomerByPumpComponent,
        CountTotalRegistrationByPumpComponent,
        SumTodayDispenseByPumpComponent,
        SumTodayRechargeByPumpComponent,
        MonthlyWaterDispenseGraphByPumpComponent,
        MonthlyRegisteredCustomerGraphByPumpComponent,
        MonthlyRechargeGraphByPumpComponent,
        TransactionListComponent,
        PumpMapComponent,
        LastWaterWithdrawalComponent,
        LatestWaterWithdrawalByPumpComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        NgxChartsModule,
        ChartsModule,
        // NgxSpinnerModule,
        AgmCoreModule.forRoot({
            //apiKey: 'AIzaSyCsux33Rkb9N_3f7wWTYtTnOoeTb5NBH_w'
            apiKey: 'AIzaSyAmOIe2jK-ABATsHQHhan1KW0xZdV5zgRc'
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        HomeModule
    ],
    providers: [
        httpInterceptorProviders,
        DatePipe
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }
