<!-- overview cards start-->
<div class="row row-cols-md-4 row-cols-sm-1 g-4 px-4 mt-2 counter">
    <div class="col-md-3 col-6">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body atm-card-top d-flex align-items-center justify-content-center">
                <!-- <h1>371</h1> -->
                <app-countTotalATM></app-countTotalATM>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>ATMs</h5>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body customer-card-top d-flex align-items-center justify-content-center">
                <!-- <h1>114,608</h1> -->
                <app-countTotalCustomer></app-countTotalCustomer>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>Customers</h5>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body dispense-card-top d-flex align-items-center justify-content-center">
                <!-- <h1>179,789</h1> -->
                <app-sumTodayDispense></app-sumTodayDispense>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>Today's Dispense (Ltr)</h5>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6">
        <div class="card h-100 text-center card-box-shadow">
            <div class="card-body recharge-card-top d-flex align-items-center justify-content-center">
                <!-- <h1>198,437</h1> -->
                <app-sumTodayRecharge></app-sumTodayRecharge>
            </div>
            <div class="card-footer common-card-bottom">
                <h5>Today's Recharge (BDT)</h5>
            </div>
        </div>
    </div>
</div>
<!-- overview cards end-->

<!--Last withdrawal section start-->
<div class="row latest-withdraw">
    <div class="col-12 mt-2">
        <div class="bg-white ms-4 me-4 py-1 my-2 last-withdrawal-shadow">
            <app-lastWaterWithdrawal></app-lastWaterWithdrawal>
        </div>
    </div>
</div>
<!--Last withdrawal section end-->

<!--charts start-->

<div class="row row-cols-md-3 row-cols-sm-1 px-3 mx-0 mb-2 chart">
    <div class="col-md-4 h-100 pl-3 mt-2">
        <app-monthlyRegisteredCustomerGraph></app-monthlyRegisteredCustomerGraph>
    </div>
    <div class="col-md-4 h-100 mt-2">
        <app-monthlyWaterDispenseGraph></app-monthlyWaterDispenseGraph>
    </div>
    <div class="col-md-4 h-100 mt-2">
        <app-monthlyRechargeGraph></app-monthlyRechargeGraph>
    </div>
</div>

<!--charts end-->

<!--map and pump section start-->
<div class="row mx-0 map-filter">
    <div class="col-md-7 px-4 map mt-2">

        <div class="map-topbar d-flex justify-content-between px-3 py-3 map-shadow">
            <div>
                <span><i class="fas fa-map text-white"></i></span>
                <span class="ps-3 text-white">Pump Locations</span>
            </div>
            <div>
                <span><i class="fas fa-share-alt text-white pe-3"></i></span>
                <span><i class="fas fa-expand text-white"></i></span>
            </div>
        </div>
        <div class="pump-location map-shadow">
            <app-pump-map [listPump]="listPump"></app-pump-map>
        </div>
    </div>
    <div class="col-md-5 px-4 data-table mt-2">
        <app-weekly-dashboard-report></app-weekly-dashboard-report>
    </div>
</div>
<!--map and pump section end-->