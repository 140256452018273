import { ToastrService } from 'ngx-toastr';
import { ErrorCode } from '../enums/enums';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var NotificationService = /** @class */ (function () {
    function NotificationService(toastService) {
        this.toastService = toastService;
        this.timeOut = 3000;
    }
    NotificationService.prototype.success = function (text, title, timeout) {
        if (title === void 0) { title = 'Success'; }
        this.toastService.success(text, title, {
            timeOut: this.timeOut
        });
    };
    NotificationService.prototype.showSuccess = function (message, title) {
        this.toastService.success(message, title);
    };
    NotificationService.prototype.warn = function (text, title, timeout) {
        if (title === void 0) { title = 'Waring'; }
        this.toastService.warning(text, title, {
            timeOut: this.timeOut
        });
    };
    NotificationService.prototype.error = function (text, title, timeout) {
        if (title === void 0) { title = 'Error'; }
        this.toastService.error(text, title, {
            timeOut: this.timeOut
        });
    };
    NotificationService.prototype.info = function (text, title, timeout) {
        if (title === void 0) { title = 'Info'; }
        this.toastService.info(text, title, {
            timeOut: this.timeOut
        });
    };
    NotificationService.prototype.infoWithoutTimeLimit = function (text, title) {
        if (title === void 0) { title = 'Info'; }
        this.toastService.info(text, title, {
            disableTimeOut: true,
            closeButton: true
        });
    };
    NotificationService.prototype.dynamic = function (error) {
        if (error.status === ErrorCode.BAD_REQUEST) {
            this.warn(error.error, 'Bad Request');
        }
        if (error.status === ErrorCode.NOT_FOUND) {
            this.info(error.error, 'Not Found');
        }
        if (error.status === ErrorCode.INTERNAL_SERVER_ERROR) {
            this.error(error.error, 'Failed');
        }
        if (error.status === ErrorCode.UNAUTHORIZED) {
            this.error(error.error, 'Unauthorized');
        }
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ToastrService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
