import { Component } from '@angular/core';
import { LoaderService } from './common/utility/loader.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DrinkWell';
  constructor(public loader: LoaderService){
  }
}
