import { DashboardModernizationService } from '../../../apiServices/dashboardModernization.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-monthlyRechargeGraph',
  templateUrl: './monthlyRechargeGraph.component.html',
})
export class MonthlyRechargeGraphComponent {

  monthlyRecharge = [];
  data = [];
  monthNames = [];
  isDataUpdate: boolean = false;

  constructor(private dashboardService: DashboardModernizationService) {
  }

  ngOnInit() {
    this.getAllMonthlyRecharge();
  }

  getAllMonthlyRecharge() {
    this.dashboardService.getAllMonthlyRecharge()
      .subscribe(
        (res: any) => {
          //push data in graph
          res.forEach(x => {
            this.data.push(x.Total);
            this.monthNames.push(x.MonthName);
          });
          //to update graph
          this.isDataUpdate = true;
        });
  }

  chartOptions = {
    responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
  }

  //labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  labels = this.monthNames;
  datasets = [
    {
      label: 'Montly Recharge Amount (BDT)',
      data: this.data
    }
  ];

  // CHART COLOR.
  colors = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)',
        'rgba(58, 221, 203,.8)'
      ],
      borderColor: [
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)'
      ],
      borderWidth: 1
    }
  ]

  // CHART CLICK EVENT.
  onChartClick(event) {
   // console.log(event);
  }

}










