<!-- spinnner element is defined with id loading -->
<div class="d-flex justify-content-center">
    <img *ngIf="isLoading" class="spinner-icon" src="../../../../assets/images/icons/spinner.gif" height="70px"
        width="70px" alt="">
    <!-- <div *ngIf="isLoading" class="spinner-border" role="status" id="loaderRecharge">
    </div> -->
</div>

<!-- data from API is displayed  -->
<!-- <h1>{{ sumTodayRecharge| currency: 'BDT '}} -->
<h1>{{ sumTodayRecharge| number : '1.2-2'}}</h1>