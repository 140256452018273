import { Component, OnInit } from '@angular/core';
import { DashboardModernizationService } from 'src/app/apiServices/dashboardModernization.service';
import { EnumWeeklyReport } from 'src/app/common/enums/enums';
import { AppUtilsService } from 'src/app/common/utility/app-utils.service';
import { NotificationService } from 'src/app/common/utility/notification.service';
import { DashboardWeeklyReport, WeeklyReportSearchCritriyea } from 'src/app/models/ViewModels/vmDashboardModernization';
import { Zone } from 'src/app/models/ViewModels/zone';

@Component({
  selector: 'app-weekly-dashboard-report',
  templateUrl: './weekly-dashboard-report.component.html',
  styleUrls: ['./weekly-dashboard-report.component.scss']
})
export class WeeklyDashboardReportComponent implements OnInit {
  lstSearchCriteria = [];
  lstDashboardWeeklyReport: Array<DashboardWeeklyReport>[] = [];
  lstZone: Array<Zone>[] = [];
  weeklyReportSearchCritriyea: WeeklyReportSearchCritriyea = new WeeklyReportSearchCritriyea();
  sCriteria: any;
  pumpname: any;
  isSelectedzone: any;
  locationName: any;
  isLoading: boolean = false;
  constructor(private utility: AppUtilsService, private dashboardService: DashboardModernizationService,
    private notify: NotificationService

  ) {
    this.lstSearchCriteria = utility.enumToArray(EnumWeeklyReport);

  }

  ngOnInit() {
    this.isSelectedzone = -1;
    this.sCriteria = 1;
    this.weeklyReportSearchCritriyea.Recharge = 1;
    this.weeklyReportSearchCritriyea.Dispense = 0;
    this.weeklyReportSearchCritriyea.Registration = 0;
    this.GetDefaultWeeklyAnalysData(this.weeklyReportSearchCritriyea);
    this.GetAllZones();
  }
  GetAllZones() {
    this.dashboardService.GetAllZone()
      .subscribe(
        (res: any) => {
          this.lstZone = res;
        },
        err => null);
  }
  GetDefaultWeeklyAnalysData(sCriteria: any) {

    this.isLoading = true;
    this.dashboardService.GetWeeklyAnalysReport(sCriteria)
      .subscribe(
        (res: any) => {
          this.lstDashboardWeeklyReport = res;
          this.isLoading = false;
        },
        err => this.isLoading = false);
  }

  Selectedvalue() {
    if (this.sCriteria == 1) {
      this.weeklyReportSearchCritriyea.Recharge = 1;
      this.weeklyReportSearchCritriyea.Dispense = 0;
      this.weeklyReportSearchCritriyea.Registration = 0;
      this.GetDefaultWeeklyAnalysData(this.weeklyReportSearchCritriyea);
    } else if (this.sCriteria == 2) {
      this.weeklyReportSearchCritriyea.Recharge = 0;
      this.weeklyReportSearchCritriyea.Dispense = 2;
      this.weeklyReportSearchCritriyea.Registration = 0;
      this.GetDefaultWeeklyAnalysData(this.weeklyReportSearchCritriyea);
    } else if (this.sCriteria == 3) {
      this.weeklyReportSearchCritriyea.Recharge = 0;
      this.weeklyReportSearchCritriyea.Dispense = 0;
      this.weeklyReportSearchCritriyea.Registration = 3;

      this.GetDefaultWeeklyAnalysData(this.weeklyReportSearchCritriyea);
    }
  }
  GetByPumName() {

    this.weeklyReportSearchCritriyea.PumName = this.pumpname;
    if (this.pumpname != undefined && this.pumpname != null && this.pumpname != '') {
      this.isLoading = true;
      this.dashboardService.GetByPumName(this.weeklyReportSearchCritriyea)
        .subscribe(
          (res: any) => {
            this.isLoading = false;
            this.lstDashboardWeeklyReport = res;
          },
          err => this.isLoading = false);
    } else {
      return;

    }

  }
  GetByZone() {
    this.weeklyReportSearchCritriyea.ZoneID = this.isSelectedzone;
    if (this.isSelectedzone != undefined && this.isSelectedzone != null && this.isSelectedzone != '' && this.isSelectedzone > 0) {
      this.isLoading = true;
      this.dashboardService.GetByZone(this.weeklyReportSearchCritriyea)
        .subscribe(
          (res: any) => {

            this.isLoading = false;
            this.lstDashboardWeeklyReport = res;
          },
          err =>
            this.isLoading = false);
    } else {
      return;
    }

  }
  GetByLocationName() {

    this.weeklyReportSearchCritriyea.LocationName = this.locationName;
    if (this.locationName != undefined && this.locationName != null && this.locationName != '') {
      this.isLoading = true;
      this.dashboardService.GetByLocationName(this.weeklyReportSearchCritriyea)
        .subscribe(
          (res: any) => {
            this.lstDashboardWeeklyReport = res;
            this.isLoading = false;
          },
          err => this.isLoading = false);
    }
    else {
      return;
    }


  }
}
