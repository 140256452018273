<!--table topbar start-->
<div class="row filter-topbar d-flex align-items-center px-3 py-2 mx-0">
    <div class="col px-0">
        <span class="text-white mr-2">Pumps </span>
        <img *ngIf="isLoading" src="../../../assets/images/icons/dual_ring.gif" height="10px" width="10px" alt="loading">
    </div>
    <div class="col">
        <div class="row">
            <div class="input-group input-group-sm ">

                <select class="form-control form-controll-margin input-sm filter-input" [(ngModel)]="sCriteria"
                    (change)="Selectedvalue()">
                    <option *ngFor="let crt of lstSearchCriteria" [value]="crt.key">{{crt.value}}</option>
                </select>


            </div>
        </div>
    </div>
    <div class="col">
        <div class="row">
            <div class="input-group input-group-sm">
                <input class="form-control form-control-sm border-end-0 border filter-input" type="text"
                    placeholder="Pump Name" [(ngModel)]="pumpname">

                <button class="btn btn-sm btn-outline-secondary bg-white border-start-0 border-bottom-1 border"
                    type="button" (click)="GetByPumName()">
                    <i class="fa fa-search"></i>
                </button>
                <!-- <span class="input-group-append">

                  </span> -->
            </div>
        </div>
    </div>
    <div class="col">
        <div class="row">
            <div class="input-group input-group-sm">
                <select class="form-control form-controll-margin input-sm filter-input" [(ngModel)]="isSelectedzone">
                    <option [value]=-1>--Select--</option>
                    <option *ngFor="let crt of lstZone" [value]="crt.ZoneId">{{crt.ZoneName}}</option>
                </select>
                <button class="btn btn-sm btn-outline-secondary bg-white border-start-0 border-bottom-1 border"
                    type="button" (click)="GetByZone()">
                    <!-- <i class="fas fa-caret-down"></i> -->
                    <i class="fa fa-search"></i>
                </button>
                <!-- <span class="input-group-append">

                  </span> -->
            </div>
        </div>
    </div>
    <div class="col">
        <div class="row">
            <div class="input-group input-group-sm">
                <input class="form-control form-control-sm border-end-0 border filter-input" type="text"
                    placeholder="Locations" [(ngModel)]="locationName">
                <button class="btn btn-sm btn-outline-secondary bg-white border-start-0 border-bottom-1 border"
                    type="button" (click)="GetByLocationName()">
                    <i class="fa fa-search"></i>
                    <!-- <i class="fas fa-caret-down"></i> -->
                </button>
                <!-- <span class="input-group-append">

                  </span> -->
            </div>
        </div>
    </div>
</div>
<!--table topbar end-->

<!--table topbar start-->

<div class="table-responsive table-shadow">
    <table class="table table-sm bg-white">

        <tbody>
            <tr>
                <th colspan="2" class="th-color theader">Pumps</th>
                <th class="th-color theader">Zones</th>
                <th class="th-color theader">ATMs</th>
                <th class="th-color theader text-right">Balance (BDT)</th>
                <th class="th-color theader text-center">Details</th>
            </tr>
            <tr *ngFor="let report of lstDashboardWeeklyReport">
                <td>{{ report.PumpName }}
                </td>
                <td>
                    <span class="ps-3">
                        <span *ngIf="report.WeeklyAnlisy==1"> <i
                                class="fas fa-angle-up text-success text-right"></i></span>
                        <span *ngIf="report.WeeklyAnlisy==2"><i
                                class="fas fa-angle-down text-danger text-right"></i></span>
                    </span>
                </td>
                <td>{{ report.ZoneName }}</td>
                <td>{{ report.TotalATM }}</td>
                <!-- <td class="text-right">{{ report.Balance| currency: 'BDT '}}</td> -->
                <td class="text-right">{{ report.Balance| number : '1.2-2'}}</td>
                <!--<td><a [routerLink]="['/dashboard-pumpDetail',report.BoothID]" routerLinkActive="active"><i class="fas fa-info-circle th-color"></i></a> </td> -->
                <td class="text-center">
                    <!-- <a routerLink="/dashboard-pumpDetail/{{report.BoothID}}"
                        routerLinkActive="active">
                        <i class="fas fa-info-circle th-color"></i>
                    </a> -->
                    <button routerLink="/dashboard-pumpDetail/{{report.BoothID}}" [title]="'View '+report.PumpName+' details'" class="btn-view border-0 px-0">
                        <div>
                            <i class="fas fa-info float-left m-1"></i>
                        </div>
                    </button>
                </td>

            </tr>

        </tbody>
    </table>
</div>

<!--table topbar end-->