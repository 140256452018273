import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '../common/constant/api-constant';

@Injectable({
  providedIn: 'root'
})
export class DashboardModernizationByPumpService {

  constructor(private http: HttpClient) { }

  public GetTodaysTotalRegistrationByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalRegistrationByPump, pumpID);
  }

  public GetTodaysTotalCustomerByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalCustomerByPump, pumpID);
  }

  public GetTodaysTotalDispenseByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalDispenseByPump, pumpID);
  }

  public GetTodaysTotalRechargeByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalRechargeByPump, pumpID);
  }

  public GetAllMonthlyRegisteredCustomerByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetAllMonthlyRegisteredCustomerByPump, pumpID);
  }

  public GetAllMonthlyWaterDispenseByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetAllMonthlyWaterDispenseByPump, pumpID);
  }

  public GetAllMonthlyRechargeByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetAllMonthlyRechargeByPump, pumpID);
  }

  public GetLatestWaterWithdrawalByPump(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetLatestWaterWithdrawalByPump, pumpID);
  }
  public GetPumpByID(pumpID: number) {
    return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetPumpByID, pumpID);
  }

}
