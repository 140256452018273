/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./lastWaterWithdrawal.component";
import * as i2 from "../../../apiServices/dashboardModernization.service";
var styles_LastWaterWithdrawalComponent = [];
var RenderType_LastWaterWithdrawalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LastWaterWithdrawalComponent, data: {} });
export { RenderType_LastWaterWithdrawalComponent as RenderType_LastWaterWithdrawalComponent };
export function View_LastWaterWithdrawalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "row py-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "col-md-2 col-12"], ["style", "font-family: Rajdhani, sans-serif; color: #0089cf"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Last Water withdrawal | "])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "col-md-10 col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "marquee", [["behavior", "loop"], ["direction", "left"], ["scrolldelay", "200"], ["style", "font-family: Rajdhani, sans-serif; color: #0a0a0af1"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.strLastWaterWithdrawal; _ck(_v, 6, 0, currVal_0); }); }
export function View_LastWaterWithdrawalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-lastWaterWithdrawal", [], null, null, null, View_LastWaterWithdrawalComponent_0, RenderType_LastWaterWithdrawalComponent)), i0.ɵdid(1, 245760, null, 0, i1.LastWaterWithdrawalComponent, [i2.DashboardModernizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LastWaterWithdrawalComponentNgFactory = i0.ɵccf("app-lastWaterWithdrawal", i1.LastWaterWithdrawalComponent, View_LastWaterWithdrawalComponent_Host_0, {}, {}, []);
export { LastWaterWithdrawalComponentNgFactory as LastWaterWithdrawalComponentNgFactory };
