export class VMDashboardNew {


}

export class DashboardWeeklyReport {
    BoothID: number
    PumpName: string;
    ZoneID:number;
    ZoneName: string;
    TotalATM: number;
    Balance: number;
    WeeklyAnlisy:number;

}export class WeeklyReportSearchCritriyea {
    Recharge: number
    Dispense: number;
    Registration:number;  
    PumName:string;
    ZoneID:number;
    LocationName:string;
}

