/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./menu-card.component";
import * as i4 from "@angular/router";
var styles_MenuCardComponent = [i0.styles];
var RenderType_MenuCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuCardComponent, data: {} });
export { RenderType_MenuCardComponent as RenderType_MenuCardComponent };
export function View_MenuCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "card  p-2 mx-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "card-body py-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "menu-icon d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "img", [["alt", ""]], [[8, "src", 4], [8, "height", 0]], null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { height: 0, width: 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "menu-label d-flex align-items-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, _co.iconHeight, _co.iconWidth); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; var currVal_1 = _co.iconHeight; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.text; _ck(_v, 7, 0, currVal_3); }); }
export function View_MenuCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-card", [], null, null, null, View_MenuCardComponent_0, RenderType_MenuCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuCardComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuCardComponentNgFactory = i1.ɵccf("app-menu-card", i3.MenuCardComponent, View_MenuCardComponent_Host_0, { iconHeight: "iconHeight", iconWidth: "iconWidth", icon: "icon", text: "text", route: "route" }, {}, []);
export { MenuCardComponentNgFactory as MenuCardComponentNgFactory };
