import { DashboardModernizationByPumpService } from './../../../apiServices/dashboardModernizationByPump.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { RELOAD_INTERVAL_TIME } from 'src/app/common/constant/app-constants';

@Component({
  selector: 'app-countTotalCustomerByPump',
  templateUrl: './countTotalCustomerByPump.component.html',
  styleUrls: ['./countTotalCustomerByPump.component.scss']
})
export class CountTotalCustomerByPumpComponent implements OnDestroy {

  @Input() pumpID: number;
  todaysTotalCustomers: number;
  isLoading:boolean = false;

  private reloadInterval;

  constructor(private dashboardService: DashboardModernizationByPumpService) {
  }

  ngOnInit() {
    this.getTodaysTotalCustomerByPump();
    this.reloadInterval = setInterval(()=>{
      this.getTodaysTotalCustomerByPump();
    }, RELOAD_INTERVAL_TIME)
  }

  ngOnDestroy(): void {
      if(this.reloadInterval){
        clearInterval(this.reloadInterval);
      }
  }

  getTodaysTotalCustomerByPump() {
    this.isLoading = true;
    this.dashboardService.GetTodaysTotalCustomerByPump(this.pumpID)
      .subscribe(
        (res: any) => {
          this.todaysTotalCustomers = res;
          //hide loader
          this.isLoading = false;
        },
        err => this.isLoading = false);
  }
}










