<!--table topbar start-->
<div class="map-topbar d-flex justify-content-between px-3 py-2 map-shadow header">
  <div>
      <span class="text-white">{{headerTitle}}</span>
  </div>
  <div *ngIf="isLoading" class="float-right">
      <img src="../../../../assets/images/icons/dual_ring.gif" height="25px" width="25px" alt="loading...">
  </div>
</div>
<!--table topbar end-->

<!--table topbar start-->

<div class="table-responsive bg-white px-3 table-shadow latest-transaction details">
  <table class="table table-sm">
      <tbody>
          <tr>
              <th class="th-color bg-white theader">Customer</th>
              <th class="th-color bg-white theader">Time</th>
              <th class="th-color bg-white theader text-right">Liter</th>
              <th class="th-color bg-white theader text-right">Taka</th>
          </tr>
          <tr *ngFor="let transaction of transactions">
              <td>{{transaction.CustomerName}}</td>
              <td>{{transaction.CreatedDate | date:'hh:mm a'}}</td>
              <td class="text-right">{{transaction.WaterAmount | number:'0.1-2'}}</td>
              <td class="text-right">{{transaction.WaterPrice | number:'0.1-2'}}</td>
          </tr>
      </tbody>
  </table>
</div>
<!--table topbar end-->