import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '../common/constant/api-constant';

@Injectable({
  providedIn: 'root'
})
export class DashboardModernizationService {

  constructor(private http: HttpClient) { }

  public GetTotalATM() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetTotalATM);
  }

  public GetTotalCustomer() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetTotalCustomer);
  }

  public GetTodayDispense() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetTodayDispense);
  }

  public GetTodayRecharge() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetTodayRecharge);
  }

  public getAllMonthlyRegisteredCustomer() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetAllMonthlyRegisteredCustomer);
  }

  public getAllMonthlyWaterDispense() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetAllMonthlyWaterDispense);
  }

  public getAllMonthlyRecharge() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetAllMonthlyRecharge);
  }

  public getLastWaterWithdrawal() {
    return this.http.get(ApiConstant.DashboardModernizationApi.GetLastWaterWithdrawal);
  }

  public GetPumpList() {
    return this.http.post(ApiConstant.DashboardModernizationApi.GetAllPump, {});
  }

  // ......///
  public GetWeeklyAnalysReport(recarchcategory: any) {
    return this.http.post(ApiConstant.DashboardModernizationApi.GetDashBoardWeeklyAnalysDate, recarchcategory);
  }

  public GetByPumName(recarchcategory: any) {
    return this.http.post(ApiConstant.DashboardModernizationApi.GetReportByPumName, recarchcategory);
  }

  public GetAllZone() {
    return this.http.post(ApiConstant.ZoneApi.GetAllZone, {});
  }
  public GetByZone(recarchcategory: any) {
    return this.http.post(ApiConstant.DashboardModernizationApi.GetByZone, recarchcategory);
  }

  public GetByLocationName(recarchcategory: any) {
    return this.http.post(ApiConstant.DashboardModernizationApi.GetByLocationName, recarchcategory);
  }
}
