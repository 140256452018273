import { DashboardModernizationService } from './../../../apiServices/dashboardModernization.service';
import { Component, OnDestroy } from '@angular/core';
import { RELOAD_INTERVAL_TIME } from 'src/app/common/constant/app-constants';

@Component({
  selector: 'app-sumTodayRecharge',
  templateUrl: './sumTodayRecharge.component.html',
  styleUrls: ['./sumTodayRecharge.component.scss']
})
export class SumTodayRechargeComponent implements OnDestroy {

  sumTodayRecharge: number;
  isLoading:boolean = false;

  private reloadInterval;

  constructor(private dashboardService: DashboardModernizationService) {
  }

  ngOnInit() {
    this.getTodayRecharge();
    this.reloadInterval = setInterval(()=>{
      this.getTodayRecharge();
    },RELOAD_INTERVAL_TIME)
  }

  ngOnDestroy(): void {
      if(this.reloadInterval){
        clearInterval(this.reloadInterval);
      }
  }

  getTodayRecharge() {
    this.isLoading = true;
    this.dashboardService.GetTodayRecharge()
      .subscribe(
        (res: any) => {
          this.sumTodayRecharge = res;
          //hide loader
          this.isLoading = false;
        },
        err => this.isLoading = false);
  }
}










