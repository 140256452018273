/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./menu-list.component";
import * as i4 from "@angular/router";
var styles_MenuListComponent = [i0.styles];
var RenderType_MenuListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuListComponent, data: {} });
export { RenderType_MenuListComponent as RenderType_MenuListComponent };
export function View_MenuListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "list-group-item border-0 mb-1 py-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "float-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "icon"], ["height", "20px"], ["width", "20px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "mx-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "icon"], ["height", "15px"], ["src", "../../../assets//images/icons/chevron.svg"], ["width", "15px"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "list-group-item border-0 mb-1 py-3"; var currVal_1 = _co.border; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.icon; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.text; _ck(_v, 5, 0, currVal_3); }); }
export function View_MenuListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-list", [], null, null, null, View_MenuListComponent_0, RenderType_MenuListComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuListComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuListComponentNgFactory = i1.ɵccf("app-menu-list", i3.MenuListComponent, View_MenuListComponent_Host_0, { icon: "icon", text: "text", route: "route", border: "border" }, {}, []);
export { MenuListComponentNgFactory as MenuListComponentNgFactory };
