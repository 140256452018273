/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng2-charts/charts/charts";
import * as i2 from "@angular/common";
import * as i3 from "./monthlyRechargeGraphByPump.component";
import * as i4 from "../../../apiServices/dashboardModernizationByPump.service";
var styles_MonthlyRechargeGraphByPumpComponent = [];
var RenderType_MonthlyRechargeGraphByPumpComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MonthlyRechargeGraphByPumpComponent, data: {} });
export { RenderType_MonthlyRechargeGraphByPumpComponent as RenderType_MonthlyRechargeGraphByPumpComponent };
function View_MonthlyRechargeGraphByPumpComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "canvas", [["baseChart", ""], ["class", "bg-white chart-box-shadow"]], null, [[null, "chartClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("chartClick" === en)) {
        var pd_0 = (_co.onChartClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.BaseChartDirective, [i0.ElementRef], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"], legend: [5, "legend"] }, { chartClick: "chartClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasets; var currVal_1 = _co.labels; var currVal_2 = _co.chartOptions; var currVal_3 = "bar"; var currVal_4 = _co.colors; var currVal_5 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_MonthlyRechargeGraphByPumpComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MonthlyRechargeGraphByPumpComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDataUpdate; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MonthlyRechargeGraphByPumpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-monthlyRechargeGraphByPump", [], null, null, null, View_MonthlyRechargeGraphByPumpComponent_0, RenderType_MonthlyRechargeGraphByPumpComponent)), i0.ɵdid(1, 114688, null, 0, i3.MonthlyRechargeGraphByPumpComponent, [i4.DashboardModernizationByPumpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MonthlyRechargeGraphByPumpComponentNgFactory = i0.ɵccf("app-monthlyRechargeGraphByPump", i3.MonthlyRechargeGraphByPumpComponent, View_MonthlyRechargeGraphByPumpComponent_Host_0, { pumpID: "pumpID" }, {}, []);
export { MonthlyRechargeGraphByPumpComponentNgFactory as MonthlyRechargeGraphByPumpComponentNgFactory };
