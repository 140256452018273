/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./countTotalCustomer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./countTotalCustomer.component";
import * as i4 from "../../../apiServices/dashboardModernization.service";
var styles_CountTotalCustomerComponent = [i0.styles];
var RenderType_CountTotalCustomerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountTotalCustomerComponent, data: {} });
export { RenderType_CountTotalCustomerComponent as RenderType_CountTotalCustomerComponent };
function View_CountTotalCustomerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "spinner-icon"], ["height", "70px"], ["src", "../../../../assets/images/icons/spinner.gif"], ["width", "70px"]], null, null, null, null, null))], null, null); }
export function View_CountTotalCustomerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountTotalCustomerComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.totalCustomer, "1.0-2")); _ck(_v, 5, 0, currVal_1); }); }
export function View_CountTotalCustomerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-countTotalCustomer", [], null, null, null, View_CountTotalCustomerComponent_0, RenderType_CountTotalCustomerComponent)), i1.ɵdid(1, 245760, null, 0, i3.CountTotalCustomerComponent, [i4.DashboardModernizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountTotalCustomerComponentNgFactory = i1.ɵccf("app-countTotalCustomer", i3.CountTotalCustomerComponent, View_CountTotalCustomerComponent_Host_0, {}, {}, []);
export { CountTotalCustomerComponentNgFactory as CountTotalCustomerComponentNgFactory };
