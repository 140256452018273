import { DashboardModernizationByPumpService } from './../../../apiServices/dashboardModernizationByPump.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { RELOAD_INTERVAL_TIME } from 'src/app/common/constant/app-constants';

@Component({
  selector: 'app-sumTodayDispenseByPump',
  templateUrl: './sumTodayDispenseByPump.component.html',
  styleUrls: ['./sumTodayDispenseByPump.component.scss']
})
export class SumTodayDispenseByPumpComponent implements OnDestroy{

  @Input() pumpID: number;
  totalDispense: number;
  isLoading:boolean = false;

  private reloadInterval;

  constructor(private dashboardService: DashboardModernizationByPumpService) {
  }

  ngOnInit() {
    this.GetTodaysTotalDispenseByPump();
    this.reloadInterval = setInterval(()=>{
      this.GetTodaysTotalDispenseByPump();
    }, RELOAD_INTERVAL_TIME)
  }

  ngOnDestroy(): void {
      if(this.reloadInterval){
        clearInterval(this.reloadInterval);
      }
  }

  GetTodaysTotalDispenseByPump() {
    this.isLoading = true;
    this.dashboardService.GetTodaysTotalDispenseByPump(this.pumpID)
      .subscribe(
        (res: any) => {
          this.totalDispense = res;
          //hide loader
          this.isLoading = false;
        },
        err => this.isLoading = false);
  }
}










