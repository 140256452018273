import { HttpResponse } from '@angular/common/http';
import { RequestMessage } from '../../models/RequestMessage';
import { LoaderService } from './loader.service';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../../apiServices/auth/auth.service';
import { NotificationService } from './notification.service';
import * as i0 from "@angular/core";
import * as i1 from "./loader.service";
import * as i2 from "../../apiServices/auth/auth.service";
import * as i3 from "./notification.service";
var RequestInterceptorService = /** @class */ (function () {
    function RequestInterceptorService(loaderService, auth, notification) {
        this.loaderService = loaderService;
        this.auth = auth;
        this.notification = notification;
    }
    RequestInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        var requestMessage = new RequestMessage(this.auth.getLoggedUsername(), 0, req.body, this.auth.getLoggedUserType());
        var customReq = req.clone({
            body: requestMessage
        });
        this.loaderService.startLoader();
        return next.handle(customReq).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
            }
        }, function (error) {
            _this.notification.dynamic(error);
        }), finalize(function () {
            _this.loaderService.stopLoader();
        }));
    };
    RequestInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function RequestInterceptorService_Factory() { return new RequestInterceptorService(i0.inject(i1.LoaderService), i0.inject(i2.AuthService), i0.inject(i3.NotificationService)); }, token: RequestInterceptorService, providedIn: "root" });
    return RequestInterceptorService;
}());
export { RequestInterceptorService };
