import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DashboardModernizationByPumpService } from 'src/app/apiServices/dashboardModernizationByPump.service';
import { RELOAD_INTERVAL_TIME } from 'src/app/common/constant/app-constants';
import { vmLastWaterWithdrawal } from 'src/app/models/ViewModels/vmLastWaterWithdrawal';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() headerTitle:string = 'Transactions';
  @Input() pumpID:number;
  isLoading:boolean = false;

  private reloadInterval;

  transactions:vmLastWaterWithdrawal[]=[];
  constructor(
    private dashboardService:DashboardModernizationByPumpService
  ) { }

  ngOnInit() {
    this.reloadInterval = setInterval(()=>{
      this.getTransactions();
    }, RELOAD_INTERVAL_TIME)
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.getTransactions();
  }

  ngOnDestroy(): void {
      if(this.reloadInterval){
        clearInterval(this.reloadInterval);
      }
  }

  getTransactions(){
    this.isLoading = true;
    this.dashboardService.GetLatestWaterWithdrawalByPump(this.pumpID)
    .subscribe(res=>{
      if(res){
        this.transactions = (res as vmLastWaterWithdrawal[]).map(item=>{
          item.CreatedDate = new Date(item.CreatedDate+'Z');
          return item;
        });
        this.isLoading = false;
      }
    },err=>this.isLoading = false)
  }
  

}
