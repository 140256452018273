export class VMPumpDetail{
    PumpID:number;
    PumpName:string;
    ZoneName:string;
    OperatingHourStart:string = '--:--';
    OperatingHourEnd:string = '--:--';
    Location:string;
    Latitude:number;
    Longitude:number;
    Photo:string;
}