import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { DashboardModernizationByPumpService } from 'src/app/apiServices/dashboardModernizationByPump.service';
import { DashboardVm, PumpView } from 'src/app/models/ViewModels/dashboard-model';
import { VMPumpDetail } from 'src/app/models/ViewModels/vmPumpDetail';

@Component({
    selector: 'app-dashboard-pump-detail',
    templateUrl: './dashboard-pump-detail.component.html',
    styleUrls: [
        './dashboard-pump-detail.component.scss',
        '../../../assets/css/dashboard.css'
    ]
})
export class DashboardPumpDetailComponent implements OnInit {
    public objDashboard: DashboardVm = new DashboardVm();
    pumpDetail:VMPumpDetail = new VMPumpDetail();
    pumMap:PumpView = new PumpView();
    pumpID: number;
    chartOptions = {
        responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
    }

    labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // STATIC DATA FOR THE CHART IN JSON FORMAT.
    chartData = [
        {
            label: 'Montly Water Dispense (Ltr)',
            data: [40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 100]
        }
    ];

    // CHART COLOR.
    colors = [
        {
            barThickness: 20,
            backgroundColor: [
                'rgba(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgba(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)'
            ],
            borderColor: [
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)'
            ],
            borderWidth: 1
        }
    ]


    chartDataRecharge = [
        {
            label: 'Montly Recharge Amount (BDT)',
            data: [32, 15, 27, 25, 8, 23, 24, 32, 39.10, 7, 27, 23]
        }
    ];

    // CHART COLOR.
    colorsRecharge = [
        {
            barThickness: 20,
            backgroundColor: [
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)',
                'rgba(58, 221, 203,.8)'
            ],
            borderColor: [
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)',
                'rgb(78, 207, 253,.8)'
            ],
            borderWidth: 1
        }
    ]
    //..........
    chartDataline = [
        {
            label: 'Monthly New Cards Sold',
            data: [3, 15, 27, 25, 8, 23, 24, 32, 36.10, 7]
        }
    ];

    // CHART COLOR.
    colorsline = [
        {
            barThickness: 20,
            backgroundColor: [
                'rgba(252, 188, 60,.2)'
            ],
            borderColor: [
                'rgba(58, 221, 203,.8)'
            ],
            borderWidth: 1
        }
    ]


    // CHART CLICK EVENT.
    onChartClick(event) {
       // console.log(event);
    }

    constructor(
        private activateRoute:ActivatedRoute,
        private dashboardService:DashboardModernizationByPumpService
        ) {

    }

    ngOnInit() {
        // this.dashboardService.GetStatus()
        // .subscribe(
        //     (res) => {
        //         this.objDashboard = <DashboardVm>res;
        //     }
        // );
        this.activateRoute.params.subscribe(data=>{
            if(data && data.pumpDetailID){
                this.pumpID = data.pumpDetailID;
                this.getPumpDetail(data.pumpDetailID);
            }
        })
    }

    getPumpDetail(pumpDetailID:number){
        this.dashboardService.GetPumpByID(pumpDetailID)
        .pipe(
            map((res:VMPumpDetail)=>{
                res.Latitude = Number(res.Latitude);
                res.Longitude = Number(res.Longitude);
                res.OperatingHourStart = res.OperatingHourStart?res.OperatingHourStart:'--:--';
                res.OperatingHourEnd = res.OperatingHourEnd?res.OperatingHourEnd:'--:--';
                return res;
            }
        ))
        .subscribe((res)=>{
            if(res){
                this.pumpDetail = res;
                this.pumMap.Lat = this.pumpDetail.Latitude;
                this.pumMap.Lng = this.pumpDetail.Longitude;
                this.pumMap.Location = this.pumpDetail.Location;
                this.pumMap.PumpName = this.pumpDetail.PumpName;
            }
        },err=>{
            console.error(err);
        })
    }
}
