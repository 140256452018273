import { HttpClient } from '@angular/common/http';
import { ApiConstant } from '../common/constant/api-constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DashboardModernizationByPumpService = /** @class */ (function () {
    function DashboardModernizationByPumpService(http) {
        this.http = http;
    }
    DashboardModernizationByPumpService.prototype.GetTodaysTotalRegistrationByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalRegistrationByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetTodaysTotalCustomerByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalCustomerByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetTodaysTotalDispenseByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalDispenseByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetTodaysTotalRechargeByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetTodaysTotalRechargeByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetAllMonthlyRegisteredCustomerByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetAllMonthlyRegisteredCustomerByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetAllMonthlyWaterDispenseByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetAllMonthlyWaterDispenseByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetAllMonthlyRechargeByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetAllMonthlyRechargeByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetLatestWaterWithdrawalByPump = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetLatestWaterWithdrawalByPump, pumpID);
    };
    DashboardModernizationByPumpService.prototype.GetPumpByID = function (pumpID) {
        return this.http.post(ApiConstant.DashboardModernizationByPumpApi.GetPumpByID, pumpID);
    };
    DashboardModernizationByPumpService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardModernizationByPumpService_Factory() { return new DashboardModernizationByPumpService(i0.inject(i1.HttpClient)); }, token: DashboardModernizationByPumpService, providedIn: "root" });
    return DashboardModernizationByPumpService;
}());
export { DashboardModernizationByPumpService };
