import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var AuthorizationService = /** @class */ (function () {
    function AuthorizationService(authService) {
        this.authService = authService;
    }
    AuthorizationService.prototype.IsSuperAdmin = function () {
        if (this.authService.getLoggedUserType() === 1) {
            return true;
        }
        return false;
    };
    AuthorizationService.prototype.IsAdmin = function () {
        if (this.authService.getLoggedUserType() === 2) {
            return true;
        }
        return false;
    };
    AuthorizationService.prototype.IsPumpOperator = function () {
        if (this.authService.getLoggedUserType() === 4) {
            return true;
        }
        return false;
    };
    AuthorizationService.prototype.IsDataOperator = function () {
        if (this.authService.getLoggedUserType() === 5) {
            return true;
        }
        return false;
    };
    AuthorizationService.prototype.IsGovtAdmin = function () {
        if (this.authService.getLoggedUserType() === 3) {
            return true;
        }
        return false;
    };
    AuthorizationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthorizationService_Factory() { return new AuthorizationService(i0.inject(i1.AuthService)); }, token: AuthorizationService, providedIn: "root" });
    return AuthorizationService;
}());
export { AuthorizationService };
