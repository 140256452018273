<div class="hold-transition login-page-layout">
    <div class="login-box">
        <!-- /.login-logo -->
        <div class="login-box-body">
            <div class="login-logo">
                <a href="/">
                    <img src="assets/img/logo.png" width="120" alt="drink Well" />
                </a>
            </div>

            <p class="login-box-msg">Sign in to start your session</p>
                <form (submit)="onLogin()" novalidate>
                    <div class="form-group has-feedback">
                        <input type="text" class="form-control" [(ngModel)]="objUserLogin.UserName" name="Username" placeholder="Username">
                        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
                    </div>
                    <div class="form-group has-feedback">
                        <input type="password" class="form-control" [(ngModel)]="objUserLogin.Password" name="Password" placeholder="Password">
                        <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                    </div>
                    <div class="row">
                        <div class="col-xs-8">
                            <!-- <div class="checkbox icheck">
                                <label>
                                    <input type="checkbox"> Remember Me
                                </label>
                            </div> -->
                        </div>
                        <!-- /.col -->
                        <div class="col-xs-4">
                            <button type="submit" class="btn btn-primary btn-block btn-flat btn-sm" [disabled]="isDisable">{{buttonText}}</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>
            <br/>
        </div>
        <!-- /.login-box-body -->
    </div>
</div>
