import { Component, Input } from '@angular/core';
import { DashboardModernizationByPumpService } from 'src/app/apiServices/dashboardModernizationByPump.service';

@Component({
  selector: 'app-monthlyWaterDispenseGraphByPump',
  templateUrl: './monthlyWaterDispenseGraphByPump.component.html',
})
export class MonthlyWaterDispenseGraphByPumpComponent {

  monthlyWaterDispense = [];
  data = [];
  monthNames = [];
  isDataUpdate: boolean = false;
  @Input() pumpID: number;

  constructor(private dashboardService: DashboardModernizationByPumpService) {
  }

  ngOnInit() {
    this.getAllMonthlyWaterDispenseByPump();
  }

  getAllMonthlyWaterDispenseByPump() {
    this.dashboardService.GetAllMonthlyWaterDispenseByPump(this.pumpID)
      .subscribe(
        (res: any) => {
          this.monthlyWaterDispense = res.map(t => t.Total)
          //push data in graph
          res.forEach(x => {
            this.data.push(x.Total);
            this.monthNames.push(x.MonthName);
          });
          //to update graph
          this.isDataUpdate = true;
        });
  }

  chartOptions = {
    responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
  }

  //labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  labels = this.monthNames;
  datasets = [
    {
      label: 'Monthly Water Dispense (Litre)',
      data: this.data
    }
  ];

  // CHART COLOR.
  colors = [
    {
      barThickness: 20,
      backgroundColor: [
        'rgba(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgba(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)'
      ],
      borderColor: [
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)',
        'rgb(78, 207, 253,.8)'
      ],
      borderWidth: 1
    }
  ]

  // CHART CLICK EVENT.
  onChartClick(event) {
   // console.log(event);
  }
}










