import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss']
})
export class MenuCardComponent implements OnInit {
  @Input() iconHeight = '40px';
  @Input() iconWidth = '40px';
  @Input() icon = '';
  @Input() text = 'Menu';
  @Input() route = '/';

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigate(){
    this.router.navigate([this.route])
  }

}
