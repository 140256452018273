import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {

  @Input() icon = '';
  @Input() text = 'Item';
  @Input() route = '/';
  @Input() border = 'border-bottom'

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigate(){
    this.router.navigate([this.route])
  }

}
