import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/apiServices/auth/auth.service';
import { AuthorizationService } from 'src/app/apiServices/auth/authorization.service';
declare let $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    isSettingOpen = false;
    isLogOpen = false;
    isReportOpen = false;
    constructor(public authService: AuthService,
        public authorizeService: AuthorizationService) {}

    ngOnInit() {
    //   $('.main-sidebar').pushMenu('collapseScreenSize');
    $(document).ready( function() {
            if ($(window).width() < 514) {
                $('body').addClass('mobile');
            } else {
                $('body').removeClass('mobile');
                }
            });

            $(document).on('click' , '.mobile  .mobile_menu' , function(e) {
                 //  $('body').addClass('sidebar-mini sidebar-collapse');
                $('body').removeClass('sidebar-open');
                // e.stopImmediatePropagation();
            e.preventDefault();
        });
     }
    openSettingMenu() {
        this.isSettingOpen = !this.isSettingOpen;
    }
    openLogMenu() {
        this.isLogOpen = !this.isLogOpen;
    }

    openReportMenu() {
        this.isReportOpen = !this.isReportOpen;
    }
}
