<!-- Shortcut menu start -->
<div class="row mx-0 my-3 p-2 shortcut">
  <div class="col-12 px-4">
    <b>All Shortcut</b>
  </div>
  <div *ngIf="this.authorizeService.IsDataOperator()==false" class="col-md-3 col-12 px-4 pt-3">
    <div (click)="navigateDashboard()" class="card m-0 dashboard-menu mx-0">
      <div class="card-body p-4">
        <div class="menu-icon d-flex align-items-center">
          <img src="../../../assets/images/icons/menu_dashboard.svg" height="80px" width="80px" alt="">
        </div>
        <div class="menu-label d-flex align-items-center">
          Dashboard
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{'col-md-12':this.authorizeService.IsDataOperator(),'col-md-9':!this.authorizeService.IsDataOperator()}" class="col-md-9 col-12 px-3">
    <div class="row m-0">
      <div *ngFor="let idx of userAccess.shortcuts" class="col-4 p-2 px-0">
          <app-menu-card
          [route]="listMenu.shortcuts[idx].route"
          [icon]="listMenu.shortcuts[idx].icon"
          [iconHeight]="listMenu.shortcuts[idx].iconHeight"
          [iconWidth]="listMenu.shortcuts[idx].iconWidth"
          [text]="listMenu.shortcuts[idx].text">
        </app-menu-card>
      </div>
    </div>
  </div>
</div>
<!-- Shortcut menu end -->

<div *ngIf="userAccess.reports.length>0 || userAccess.logs.length>0" class="row mx-0">
  <hr class="my-2"/>
</div>

<!-- Report-log start -->
<div *ngIf="userAccess.reports.length>0 || userAccess.logs.length>0" class="row mx-0 py-2 mb-3 px-4 report">
  <div *ngIf="userAccess.reports.length>0" class="col-md-6 px-2">
    <div class="row mx-0 py-2 section-title">
      <b>Reports</b>
    </div>
    <div class="card report-card">
      <div class="card-body">
        <ul class="list-group">
          <app-menu-list *ngFor="let idx of userAccess.reports"
          [route]="listMenu.reports[idx].route" [text]="listMenu.reports[idx].text" [icon]="listMenu.reports[idx].icon"></app-menu-list>
          </ul>
      </div>
    </div>
  </div>
  <div *ngIf="userAccess.logs.length>0" class="col-md-6 px-2">
    <div class="row mx-0 py-2 section-title">
    <b>Logs</b>
  </div>
    <div class="card log-card">
      <div class="card-body">
        <ul class="list-group">
          <app-menu-list *ngFor="let idx of userAccess.logs"
          [route]="listMenu.logs[idx].route" [text]="listMenu.logs[idx].text" [icon]="listMenu.logs[idx].icon"></app-menu-list>
         </ul>
      </div>
    </div>
  </div>
</div>
<!-- Report-log end -->

<div *ngIf="userAccess.settings.length>0" class="row mx-0">
  <hr class="my-2"/>
</div>

<!-- Settings start -->
<div *ngIf="userAccess.settings.length>0" class="row mx-0 py-2 px-4 settings">
  <div class="col-12 section-title">
    <b>Settings</b>
  </div>
  <div *ngFor="let idx of userAccess.settings" class="col-3 p-2 px-0">
    <app-menu-card
    [route]="listMenu.settings[idx].route"
    [icon]="listMenu.settings[idx].icon"
    [iconHeight]="listMenu.settings[idx].iconHeight"
    [iconWidth]="listMenu.settings[idx].iconWidth"
    [text]="listMenu.settings[idx].text"
    ></app-menu-card>
  </div>
</div>
<!-- Settings end -->