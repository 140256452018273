
import { Component, OnInit } from '@angular/core';
import { UserLogin } from '../../models/ViewModels/user-login';
import { AuthService } from '../../apiServices/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  objUserLogin = new UserLogin();
  isDisable: boolean;
  buttonText: string;

  constructor(private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.initial();
  }

  private initial(isChange?: boolean) {
    this.isDisable = isChange ? true : false;
    this.buttonText = isChange ? 'Login...' : 'Login';
  }

  onLogin() {

    if (!this.objUserLogin.Password && !this.objUserLogin.UserName) {
      return false;
    }

    this.initial(true);
    this.authService.login(this.objUserLogin)
    .subscribe(
      (res) =>{

        if(this.authService.getLoggedUserType()===5){
            this.router.navigate(['account'])
        }
        else{
            this.router.navigate(['/'])
        }
      } ,
      err => this.initial()
    );
  }
}
