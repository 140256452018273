import { CustomerCountByMonth } from "./customerCountByMonth";

export class DashboardVm
{
    ActiveCustomer: number;
    ActiveBooth: number;
    LstPump: PumpView[];
    LstWaterTransaction: LatestWaterDispense[];
    TodayWaterDispense: TodayWaterDispenseVm;
    MonthlyWaterDispense: MonthlyWaterDispenseVm;
    NewAccountCountByMonth: CustomerCountByMonth[];
    RechargeSumByMonth = [];
    WaterDispenseSumByMonth = [];

    constructor() {
        this.TodayWaterDispense = new TodayWaterDispenseVm();
        this.MonthlyWaterDispense = new MonthlyWaterDispenseVm();
    }
}

export class LatestWaterDispense
{
    PumpName: string;
    CustomerName: string;
    DispenseAt: string;
    WaterDispense: number;
    TotalAmount: number;
}

export class PumpView
{
    PumpName: string;
    Location: string;
    Status: number | null;
    Lat:number|string;
    Lng: number|string;
}

export class ChartWaterDispense
{
    WaterAmount: number | null;
    DispenseAt: Date | null;
}

export class TodayWaterDispenseVm
{
    TodayWaterDispense: number;
    LstTodayWaterDispense: ChartWaterDispense[];
    constructor() {
        this.LstTodayWaterDispense = new Array<ChartWaterDispense>();
    }
}

export class MonthlyWaterDispenseVm
{
    MonthlyWaterDispense: number;
    LstMonthlyWaterDispense: ChartWaterDispense[];
    constructor() {
        this.LstMonthlyWaterDispense = new Array<ChartWaterDispense>();
    }
}
