import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
import { ApiConstant } from '../../common/constant/api-constant';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./token.service";
import * as i3 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(http, tokenService, router) {
        this.http = http;
        this.tokenService = tokenService;
        this.router = router;
    }
    AuthService.prototype.login = function (authData) {
        var _this = this;
        var body = {
            Username: authData.UserName,
            Password: authData.Password
        };
        return this.http.post(ApiConstant.AuthenticationApi.Login, body).pipe(map(function (res) {
            var loginData = res;
            _this.tokenService.SaveToken(loginData.Token);
        }));
    };
    // public CloseSession(sessionId: number): Observable<string> {
    //     return this.authHttp.post(ConstantAPI.AuthAPI.CloseSession, this.requestMessageService.GetRequestMessageWithOutObj(sessionId))
    //       .map(res => res.json());
    // }
    /* Method for logout and remove token
    * @Parameter No parameter
    * @Return Boolean
    */
    // logout() {
    //  return this.authHttp.post(ConstantAPI.AuthAPI.Logout, this.requestMessageService.GetRequestMessageWithOutObj("logout"))
    //     .map(res => res.json());
    // }
    AuthService.prototype.removeToken = function () {
        this.tokenService.RemoveToken();
        this.router.navigate(['login']);
    };
    // logoutFromAll() {
    //   this.authHttp.post(ConstantAPI.AuthAPI.LogoutAll, this.requestMessageService.GetRequestMessageWithOutObj("logout")).subscribe(res => {
    //     this.tokenService.RemoveToken();
    //     return true;
    //   }, error => {
    //     try {
    //       this.notification.error('Failed', error);
    //       return false;
    //     }catch(e) {
    //       return true;
    //     }
    //   })
    //   return true;
    // }
    /* Method for registration in the system
    * @Parameter No parameter
    * @Return Boolean
    */
    // registration(data: any) {
    //     return this.http.post(this.serverPath + '/api/account/registration', data);
    // }
    /* Method for change password
    * @Parameter newPassword, confirmPassword
    * @Return Response message from serve
    */
    AuthService.prototype.changePassword = function (changePasswordData) {
        return this.http.post(ApiConstant.AuthenticationApi.ChangePassword, changePasswordData);
    };
    /* Method for request reset password when forgot password
    * @Parameter user email
    * @Return Response string
    */
    // forgotPassword(data) {
    //   return this.http.post(this.serverPath + '', data).map(response => response.json());
    // }
    /* Method for Reset password
    * @Parameter email, new password, confirm password
    * @Return Response string
    */
    AuthService.prototype.resetPassword = function (data) {
        return this.http.post(ApiConstant.AuthenticationApi.ResetPassword, data);
    };
    /* Method for Checked that claimed user is authenticate or not ?
    * @Parameter No parameter
    * @Return Boolean
    */
    AuthService.prototype.checkLogged = function () {
        if (this.tokenService.GetToken()) {
            return !this.tokenService.isTokenExpired();
        }
        else {
            return false;
        }
    };
    AuthService.prototype.getLoggedUsername = function () {
        if (this.tokenService.GetToken()) {
            return this.tokenService.GetTokenValue('unique_name');
        }
        return '';
    };
    AuthService.prototype.getLoggedUserType = function () {
        if (this.tokenService.GetToken()) {
            return Number(this.tokenService.GetTokenValue('user_type'));
        }
        return 0;
    };
    AuthService.prototype.getLoggedEmail = function () {
        if (this.tokenService.GetToken()) {
            return this.tokenService.GetTokenValue('email');
        }
        return '';
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.TokenService), i0.inject(i3.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
