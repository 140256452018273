<div class="container">
    <div class="row py-2">
        <div class="col-md-2 col-12" style="font-family: Rajdhani, sans-serif; color: #0089cf">
            Last Water withdrawal |
        </div>
        <div class="col-md-10 col-12">
            <marquee direction="left" style="font-family: Rajdhani, sans-serif; color: #0a0a0af1" scrolldelay="200"
                behavior="loop">
                {{strLastWaterWithdrawal}}
            </marquee>
        </div>
    </div>
</div>